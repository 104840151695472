// React
import React from 'react'

// Third Party Components
import AutoResizeTextArea from "react-textarea-autosize"

// Wizard Form Context
import { WizardFormContext } from "@components/WizardForm/context"
import { QuestionCollection } from '@/models/formPage'
import { formModelUtils } from '@/models/formModelUtils'
import { objectUtils } from '@/helpers'

// Local Interfaces
interface TextAreaProps {
    placeholder: string
    pageIndex: number
    index: number
    subIndex: number
    isCustom: boolean
}

/**
 * Text Area
 * @description text area component
 */
const TextArea: React.FunctionComponent<TextAreaProps> =
    ({ placeholder, pageIndex, index, subIndex, isCustom }): React.ReactElement => {

        // Const
        const questionTypeKey = !isCustom ? "questions" : "customQuestions"

        // Context
        const {
            setDataCollection,
            customQuestions,
            templateQuestions,
            dataCollection
        } = React.useContext(WizardFormContext)

        // Handlers
        const onChange = (e: any) => {
            setDataCollection((dc: any) => {
                // Copy of data collection to be modified
                const newDc: any = [...dc]

                // If it's a custom one
                const questions: QuestionCollection = newDc[pageIndex][questionTypeKey]
                const templates = isCustom ? customQuestions : templateQuestions
                formModelUtils.setValueInQuestionCollection({
                    collection: questions,
                    index,
                    subIndex,
                    template: templates[pageIndex][index][subIndex],
                    data: { value: e.target.value }
                })

                // Set a new copy of data collection (dc)
                return newDc
            })
        }


        // Reference to current Question values (including all sub-parts)
        const currentQuestionValue = 
            objectUtils.getValueByKeys(dataCollection, 
                [pageIndex, questionTypeKey, index, subIndex, "value"], null) || ""

        return (
            <AutoResizeTextArea
                className="w-full bg-transparent font-light block focus:outline-none resize-none wiz-edit-text"
                rows={3}
                maxRows={10}
                onInput={onChange}
                placeholder={placeholder}
                value={currentQuestionValue}
            />
        )
    }

export default TextArea
