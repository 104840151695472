import React from 'react';

import highlySmiley from '../../icons/highly-smily.svg';
import somwhatSmiley from '../../icons/somewhat-smily.svg';
import lowSmiley from '../../icons/low-smily.svg';
import lightIcon from '../../icons/light-icon.svg';
import EastIcon from '@mui/icons-material/East';

import './style.css';
import { ChannelValues, TypeOfRelevency, ChannelTypes } from '../../data/report.utils';
import { HtmlTooltip } from '@/components/WizardComponents/CustomTooltip/CustomTooltip';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const formatRelevancyPerChannelForAdviceGeneration = (relevancyPerChannel?: { [key in ChannelTypes]: ChannelValues }) => {
    const { skillfullness, qualification, compatibility } = relevancyPerChannel as any || {};
    return {
        skillfullness: skillfullness === ChannelValues.NO_BLOCK ? ChannelValues.LOW : skillfullness,
        qualification: qualification === ChannelValues.NO_BLOCK ? ChannelValues.LOW : qualification,
        compatibility: compatibility === ChannelValues.NO_BLOCK ? ChannelValues.LOW : compatibility
    }

}

const generateUnqualifiedAdvice = (candiateFirstName: string, relevancyPerChannel?: { [key in ChannelTypes]: ChannelValues }) => {
    const { skillfullness, qualification, compatibility } = formatRelevancyPerChannelForAdviceGeneration(relevancyPerChannel) || {} as any;
    const low = ChannelValues.LOW
    if(
        [low].includes(skillfullness) 
        &&  [low].includes(qualification)
        &&  [low].includes(compatibility)
    ) return `Reconsider ${candiateFirstName} candidacy`;

    return `Review our recommendations`
}

const generateQualifiedAdvice = (candiateFirstName: string, relevancyPerChannel?: { [key in ChannelTypes]: ChannelValues }) => {
    const { skillfullness, qualification, compatibility } = formatRelevancyPerChannelForAdviceGeneration(relevancyPerChannel) || {} as any;
    const high = [ChannelValues.HIGH, ChannelValues.VERYHIGH]
    const medium = ChannelValues.MEDIUM
    const low = ChannelValues.LOW

    if(
        [low, medium].includes(skillfullness) 
        &&  [...high].includes(qualification)
        &&  [low, medium, ...high].includes(compatibility)
    ) return `Based on CV, consider proceeding with ${candiateFirstName}`

    if(
        [...high].includes(skillfullness) 
        &&  [...high].includes(qualification)
        &&  [medium, low].includes(compatibility)
    ) return `Proceed with ${candiateFirstName}!`

    if(
        [...high].includes(skillfullness) 
        &&  [medium].includes(qualification)
        &&  [medium, ...high].includes(compatibility)
    ) return `Proceed with ${candiateFirstName}!`

    return `Review our recommendations`;
}

const relevancyAdvice = (candiateFirstName: string, relevancyPerChannel?: { [key in ChannelTypes]: ChannelValues }) => ({
    [TypeOfRelevency.GEM]: `Proceed with ${candiateFirstName}`,
    [TypeOfRelevency.STAR]: `Proceed with ${candiateFirstName}`,
    [TypeOfRelevency.QUALIFIED]: generateQualifiedAdvice(candiateFirstName, relevancyPerChannel),
    [TypeOfRelevency.NOTQUALIFIED]: generateUnqualifiedAdvice(candiateFirstName, relevancyPerChannel)
})

const channelIcon = {
    [ChannelValues.NO_BLOCK]: lowSmiley,
    [ChannelValues.LOW]: lowSmiley,
    [ChannelValues.MEDIUM]: somwhatSmiley,
    [ChannelValues.HIGH]: highlySmiley,
    [ChannelValues.VERYHIGH]: highlySmiley
}

const channelLabels = {
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.LOW}`]: "Incompatible",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.MEDIUM}`]: "Semi-Compatible",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.HIGH}`]: "Compatible",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.VERYHIGH}`]: "Highly Compatible",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.NO_BLOCK}`]: "Compatibility N/A",

    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.LOW}`]: "Unqualified",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.MEDIUM}`]: "Semi-qualified",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.HIGH}`]: "Qualified",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.VERYHIGH}`]: "Highly Qualified",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.NO_BLOCK}`]: "Qualifications N/A",

    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.LOW}`]: "Unskilled",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.MEDIUM}`]: "Semi-skilled",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.HIGH}`]: "Skilled",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.VERYHIGH}`]: "Highly Skilled",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.NO_BLOCK}`]: "Skillfulness N/A",
}

const channelsDescription = {
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.LOW}`]: 
    "%user%'s work experience is not relevant to your requirements, therefore we predict you are most likely to ignore their CV.",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.MEDIUM}`]:
    "%user%'s work experience is somewhat relevant to your requirements, therefore we predict you are most likely to ignore their CV.",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.HIGH}`]: 
    "%user%'s work experience is very relevant to your requirements!",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.VERYHIGH}`]: 
    "%user%'s work experience is very relevant to your requirements!",
    [`${ChannelTypes.QUALIFICATION}-${ChannelValues.NO_BLOCK}`]: 
    "N/A",

    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.LOW}`]: 
    "%user% performed poorly on the professional simulation.",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.MEDIUM}`]: 
    "%user% had a mediocre performance on the professional simulation.",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.HIGH}`]: 
    "%user% performed great on the professional simulation!",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.VERYHIGH}`]: 
    "%user% performed great on the professional simulation!",
    [`${ChannelTypes.SKILLFULLNESS}-${ChannelValues.NO_BLOCK}`]: 
    "N/A",

    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.LOW}`]: 
    "%user%'s work approach and preferences are poorly compatible with yours, therefore %user% is likely to have different work practices to yours.",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.MEDIUM}`]: 
    "%user%'s work approach and preferences are somewhat compatible with yours, therefore you are likely to share some work practices.",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.HIGH}`]: 
    "%user%'s work approach and preferences are compatible with yours, therefore you are likely to work well together!",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.VERYHIGH}`]: 
    "%user%'s work approach and preferences are compatible with yours, therefore you are likely to work well together!",
    [`${ChannelTypes.COMPATIBILITY}-${ChannelValues.NO_BLOCK}`]: 
    "N/A",
}

const OurAdviceAndCandidateInsights:React.FunctionComponent<{
    channels?: {
        [key in ChannelTypes]: ChannelValues
    },
    isSkillfullnessVerified?: boolean,
    candidateRelevancy?: TypeOfRelevency,
    candidateFirstName?: string,
    onAdviseClicked?: () => void,
    onClickVerify?: () => any,
    className?: string
}> = ({
    channels,
    candidateRelevancy,
    isSkillfullnessVerified,
    candidateFirstName,
    onAdviseClicked,
    onClickVerify,
    className
}):JSX.Element => {

    const getChannelDescription = (channel: ChannelTypes | string, value: ChannelValues, candiateFirstName: string) => {
        return channelsDescription[`${channel}-${value}`]?.replaceAll('%user%', candiateFirstName)
    }

    return (
        <div className={`our-insights-container ${className ?? ""}`}>
            <div className='our-insights'>
            {channels && 
            Object.entries(channels)
            .map(([channel, value], index) => (
                <React.Fragment
                key={index}>
                    {index !== 0 &&
                    <EastIcon className='our-insights-arrow-icon'/>}
                    <div className='our-insights-insight'>
                        <HtmlTooltip
                        title={
                        <p className="our-insights-channel-description">
                            {getChannelDescription(channel, value, candidateFirstName ?? "The talents")}
                        </p>}> 
                            <div className='our-insights-insight-value'>
                            <img src={channelIcon[value]} alt="smiley" />
                            <span> 
                                {`${channelLabels[`${channel}-${value}`]}`}
                            </span>
                            </div>
                        </HtmlTooltip>
                        
                        {channel === ChannelTypes.SKILLFULLNESS && value !== ChannelValues.NO_BLOCK && isSkillfullnessVerified !== undefined ?
                        <HtmlTooltip
                        title={
                        <p className="our-insights-channel-description">
                            {isSkillfullnessVerified ? 
                            `Open questions were reviewed by one of the team members.`
                            :`You need to rank ${candidateFirstName}'s answers to open questions. We recommend giving scores here to get a complete picture of their skillfulness.`}
                        </p>}> 
                            {isSkillfullnessVerified ? 
                            <a className='our-insights-insight-verified'>
                                (Verified
                                <DoneOutlinedIcon/>)
                            </a> 
                            :
                            <a 
                            onClick={onClickVerify}
                            className='our-insights-insight-unverified'>
                                (Require verification!)
                            </a>}
                        </HtmlTooltip> 
                        : null}
                    </div>
                </React.Fragment>
            ))}
            </div>
            <div className='our-insights-advice'>
                <img src={lightIcon} alt="advice"/>
                <span>Our advice -</span>
                &nbsp;
                <span 
                onClick={onAdviseClicked}
                className='our-insights-advice-span'>
                    {relevancyAdvice(candidateFirstName ?? "The talent", channels)[candidateRelevancy!] ?? "Review our recommendations"}
                </span>
            </div>
        </div>
    )
}

export default OurAdviceAndCandidateInsights;