/**
 *
 * @module src/pages/Positions
 *
 * @description show position of specific company
 *
 * @author zohar
 * 
 */

import React, { useState, useContext, useEffect } from 'react';

// Router
import { useHistory, Link, useLocation } from "react-router-dom"

// Components
import MaterialTable from '@/components/MaterialTable';
import { ChatWrapper, Modalbox, Title } from '@/components';
import DropDownWithDotsMaterial from "@components/DropDownWithDotsMaterial";

// Third Party Components
import { FormControl, MenuItem } from '@mui/material';


// Permissions
import { AuthComponent } from "@permissions"

// Logic
import usePositions, { IPositions } from "./usePositions";

// Context
import { useAuth, IAuth } from "@auth";
import PositionPageContext from "@context/PositionContext";

// Queries
import { getPositions } from "@queries/positions";

// Helpers
import { graphQueries, getJobSimPositionNewUrl, validateEmailandDomain, navigateSmoothToDivById } from "@helpers";
import { v4 as uuidv4 } from 'uuid';

// Assets
import { getAllPositionStatuses } from "@/models/position";
import AuthContext from "@/auth/context";
import EditIcon from '@assets/icons/edit-icon.svg';
import SpaceshipIcon from '@assets/icons/spaceship-icon.svg';

// Intefaces
import { IPosition } from "@interfaces/pages/positions";

import { hotjar } from 'react-hotjar';
import env from 'react-dotenv';
import Cookies from 'js-cookie';
import { Analytics } from '@/helpers/analytics';

//google tag manager
import TagManager from 'react-gtm-module'

import '@assets/styles/buttons.css'
import { getCandidatesByCompanyId } from '@/graphql/queries/candidates';
import { getCandidatesCaregorizedByNormalGemsStarsQualified } from '../Talents/helpers';
import { TypeOfRelevency } from '../CandidateReport/V2/data/report.utils';
import RelevancyCards from '@/components/RelevencyCards';
import { ICandidate } from '../Talents/interfaces';
import { StyledSelect, TooltipIconButton } from './components';
import { GridColDef } from '@mui/x-data-grid';
import ChatRooms from '../ChatRooms';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import './style.css';
import AppContext from '@/context/AppContext';
import useLocalToast from "../../hooks/useLocalizedToast";

// Local Interfaces
interface SelectOption {
  label: string
  value: string
}

const CHAT_CONTAINER_ID = "positions-chat-container";

const Positions: React.FunctionComponent = (): React.ReactElement => {
  // Selected company
  const { user, company }: IAuth = useAuth();

  //Router
  const history = useHistory();
  const { search } = useLocation();
	const query = new URLSearchParams(search);

  const { settings } = useContext(AuthContext);
  const { locale, localizer, tr } = useContext(AppContext);

  // States
  const [originalData, setOriginalData] = useState<IPosition[]>([]);
  const [loadingPositions, setLoadingPositions] = useState<boolean>(true);
  const [loadingCandidates, setLoadingCandidates] = useState<boolean>(true);
  const [source] = useState<string>(query.get("source") ?? "direct");
  const [isDeletingPosition, setIsDeletingPosition] = useState<boolean>(false);

  const [candidatesByRelevancy, setCandidatesByRelevancy] = useState<{ [key in TypeOfRelevency]: ICandidate[] }>();

  const [chatIframeProps, setChatIframeProps] = useState<{iframeId?: string, chatUrl?: string}>({})
  const [showChat, setShowChat] = useState<boolean>(false);
  const { toast } = useLocalToast();
  const [statuses, setStatuses] = useState<SelectOption[]>([]);


  // Logic
  const {
    fetcher,
    showModal,
    positionToDelete,
    setShowModal,
    setPositionToDelete,
    deletePositionRequest,
  }: IPositions = usePositions()

  // Get all positions
  useEffect(() => {
    setLoadingPositions(true);
    fetcher(getPositions('getAllV2Positions'), 0 , 1000000, '')
      .then((data:any)=> {

        // Save data
        setOriginalData(data.items);

        //setting hotjar
        if (!settings.isLocalMode()) {
          hotjar.initialize(Number(env.HOTJAR_HJID), Number(env.HOTJAR_HJSV));
        }
      })
      .catch(console.error)
      .finally(() => setLoadingPositions(false));
  },[ company?.id ]);

  useEffect(() => {
    setLoadingCandidates(true);
    graphQueries.sendRequest(getCandidatesByCompanyId,{
      "companyId": company?.id,
      })
      .then((data: any) => {
              const { getCandidates } = data || {};
              const { candidates } = getCandidates || {};
              
              const { gemsCandidates, starsCandidates, qualifiedCandidates } = getCandidatesCaregorizedByNormalGemsStarsQualified(candidates);
              
              setCandidatesByRelevancy({
                [TypeOfRelevency.GEM]: gemsCandidates,
                [TypeOfRelevency.STAR]: starsCandidates,
                [TypeOfRelevency.QUALIFIED]: qualifiedCandidates,
                [TypeOfRelevency.NOTQUALIFIED]: []
              })
      })
      .catch(console.error)
      .finally(() => setLoadingCandidates(false));
  }, []);
  
  // Status Select Options
  useEffect(() => {
    setStatuses(getAllPositionStatuses().map((status) => ({ label: tr(status), value: status })));
  }, [locale]) 

  // Change position status handler
  const changePositionStatus = async (positionId: string, status: any) => {
    const position = originalData.find(r => r.id === positionId);

    // Check if row exist
    if (position !== undefined) {
      // Change status to given status
      position.status = status;
      // Replace old value with the new one
      setOriginalData((prevState: any) => {
        return prevState.map((r: any) => r.id === positionId ? position : r)
      });
    }
    const success = await graphQueries.setPositionStatus({ positionId, status });
    
    if (!success) {
      toast("Failed to set position status", {
        toastId: "position-status",
        autoClose: 4000,
        containerId: "default"
      })
    } else {
      const position = originalData.find(p => p.id === positionId);
      await Analytics.sendPositionEvent(`changeStatus - ${status}`, {
        position: position as any,
        user: user,
        company: company
      })
    } 
  }

  const generateAndSaveSessionIdAndType = (type: string): string => {
    const sessionId = uuidv4();
    const reportType = type;
    Cookies.set("reportSessionId", sessionId);
    Cookies.set("reportType", reportType)

    return reportType 
  }

  const onAddPositionButtonClicked = () => {

    const reportType = generateAndSaveSessionIdAndType("create");
    
    Analytics.sendPositionWizardReportEvent(reportType, {
      position_id: undefined, 
      user_id: user?.id ?? null,
      company_id: company?.id ?? null,
      screen: "create_new_position",
      event: "visit",
      source: source
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "GA_Event",
        event_action: "open_new_position",
        event_category: "workspace_activities",
        event_label: company?.name,
      },
    });
    
    history.push("/positions/wizard")
  }

  const onEditPositionClicked = (position: any) => {
    const reportFlow = generateAndSaveSessionIdAndType("update");
    Analytics.sendPositionWizardReportEvent(reportFlow, {
      position_id: position?.id, 
      user_id: user?.id ?? null,
      company_id: company?.id ?? null,
      screen: "general",
      event: "visit",
      source: source
    })
    history.push(`/positions/wizard/${position.id}`)
  }

  // Action menu items
  const actionMenuItems = (position: IPosition): any[] => (
    [
      {
        label: tr("Open Discovery Page"),
        onClick: () => {
          const a = document.createElement('a')

          a.target = "_blank"

          a.href = localizer.localizeURL(getJobSimPositionNewUrl({
            url: settings.getSetting("JOBSIMULATOR_ORIGIN"),
            companySlug: company!.slug,
            positionSlug: position!.slug,
            templatesNum: position?.templates,
            isUnboxableUser: validateEmailandDomain(user!.email, 'unboxable.com'),
            params: {
              context: "preview"
            }
          }), locale)

          a.click()
        }
        
      },
      {
        // Add Logic Delete Position
        label: tr("Delete Position"),
        onClick: () => {
          setPositionToDelete(position)
          setShowModal(true)
        } 
      }
    ]
  )
  
  // Columns
  const columns:GridColDef[] = [
    { 
      field: 'matchingTitle', 
      headerName: tr('Simulations'), 
      width: 350, 
      renderCell: (params: any) => {
      if (params.row.numberOfCandidates === 0) {
        return <div>
          <p 
          onClick={() => toast(tr("No candidates submitted for this position"), {
            containerId: "default",
            type: "info"
          })}
          className="no-underline text-gray-700 hover:text-indigo-600 font-medium cursor-pointer">
            {params.row.matchingTitle ?? params.row.title}
          </p>
        </div>
      } else {
        return <div>
          <Link
            className="no-underline text-gray-700 hover:text-indigo-600 font-medium"
            to={{ pathname: `/positions/talents/${params.row.id}`, state: {positionName: params.row.matchingTitle ?? params.row.title} }}
          >
            {params.row.matchingTitle ?? params.row.title}
          </Link>
        </div>
      }
      }
    },
    { 
      field: 'openedBy', 
      headerName: tr('Opened By'), 
      flex: 1, 
      renderCell: (params: any) => (
        <div>
          <div className="text-gray-700">{(!params.row.openedBy || params.row.openedBy === "null null") ? params.row.openedByEmail : (params.row.openedBy).replace(" null", "")}</div>
          <div className="mt-3 text-xs text-gray-400">{params.row.department}</div>
        </div>
    )},
    { 
      field: 'numberOfCandidates', 
      headerName: tr('Number of Candidates'), 
      type: 'number', 
      flex: 1, 
      align: 'left', 
      headerAlign: 'left',
      renderCell: (params: any) => {
        if (params.row.numberOfCandidates === 0) {
          return <div>
            <p className="no-underline text-gray-700 font-medium">
              {params.row.numberOfCandidates}
            </p>
          </div>
        } else {
          return <div>
            <Link
              className="no-underline text-gray-700 hover:text-indigo-600 font-medium"
              to={`/positions/talents/${params.row.id}`}
            >
              {params.row.numberOfCandidates}
            </Link>
          </div>
        }
      }
    },
    { 
      field: 'status', 
      headerName: tr('Status'), 
      width: 160,
      type: 'singleSelect', 
      renderCell: (params: any) => {
      return (
        <AuthComponent
        permissions={["companyUser"]}
        fallback={<div className="text-gray-700">{params.row.status}</div>}
      >
          <FormControl fullWidth>
            <StyledSelect
              value={(params.row.status !== null && params.row?.status?.length) > 0 ? params.row.status : ""}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={(e: any) => changePositionStatus(params.row.id, e.target.value)}
              MenuProps={{ sx: { "& .MuiList-root": { padding: "0 !important"} } }}
              >
            {statuses.map((status: SelectOption, ind: number) => (
              <MenuItem 
              style={{ fontSize: "14px" }}
              value={status.value} key={`status${ind}`}>{status.label}</MenuItem>
            ))}
          </StyledSelect> 
        </FormControl>
      </AuthComponent>
      )}},
    { 
      field: 'options',
      headerName: '', 
      flex: 1, 
      align: 'right', 
      sortable: false, 
      disableColumnMenu: true, 
      renderCell:  (params: any) => (
        <div className='flex flex-row'>
          <TooltipIconButton tooltipTitle={tr("Edit simulation")} iconSrc={EditIcon} onClick={() => onEditPositionClicked(params.row)}/>
          <TooltipIconButton tooltipTitle={tr("Edit Discovery page")} iconSrc={SpaceshipIcon} onClick={() => history.push(`/positions/wizard/discovery/${params.row.id}`)}/>
          <DropDownWithDotsMaterial options={actionMenuItems(params.row)} />
        </div>
      ) }
  ];

  return (
    <main id="positions">
      <PositionPageContext.Provider value={{ setShowModal, showModal, setPositionToDelete }}>
        <div style={{ display: "flex", flexFlow: "row", justifyContent: "space-between" }}>
          {/* Positions Page Title */}
          <Title text={tr("Simulations & Candidates")}/>
          {/* Actions */}
          <div style={{display: 'inline-flex', flexWrap: "wrap", gap: 10, padding: '38px 0', paddingTop: "0"}}>
            {/* Add Position Button */}
            <button
              onClick={() => onAddPositionButtonClicked()}
              className="unboxable-add-buttons"
              >
                {`+ ${tr("Create a new simulation")}`}
            </button>
            {company && company.id &&
            <ChatRooms 
            buttonProps={{
              className: "unboxable-add-buttons radius-chat-btn",
              onClick: () => {
                setShowChat(true)
                setTimeout(( )=> {
                  navigateSmoothToDivById(CHAT_CONTAINER_ID)
                }, 500)
              }
            }}
            onChatAvailable={(iframeId, chatUrl) => setChatIframeProps({ iframeId, chatUrl })}
            roomId={company.id}>
              <ChatBubbleIcon sx={{ fontSize: "18px", color: "#F7995E"}}/>
            </ChatRooms>}
          </div>
        </div>
        <RelevancyCards
        allPositions={true}
        loading={loadingCandidates}
        candidatesByRelevancy={candidatesByRelevancy ?? {} as any}/>
        {/* Positions Table */}
        <MaterialTable
          columns={columns}
          loading={loadingPositions}
          emptyStateTitle={tr("There are currently no open positions")}
          emptyStateBtnText={`+ ${tr("New Position")}`}
          emptyStateBtnAction={() => history.push("/positions/wizard")}
          originalData={originalData}
          searchOnColumns={['title', 'openedBy', 'status', 'numberOfCandidates']}
          enumFilters={[{
            field: "status",
            placeholder: tr("By status"),
            options: getAllPositionStatuses().map(s => ({ label: tr(s), value: s })),
            displayName: tr("Status")
          }]}
        />
        <ChatWrapper 
        showChat={showChat}
        setShowChat={setShowChat}
        chatContainerId={CHAT_CONTAINER_ID} 
        chatIframeProps={chatIframeProps as any}
        chatIsAvailable={chatIframeProps && chatIframeProps.iframeId && chatIframeProps.chatUrl ? true : false}>
          <h3 style={{ fontSize: "15px", marginBottom: "20px" }}>
            {tr("Let's discuss hiring")}
          </h3> 
        </ChatWrapper>

        {/* Confirm Delete Modalbox */}
        {showModal && (
        <Modalbox
          message={`${tr("Delete")} ${positionToDelete?.title}?`}
          description={`${tr("You're about to permanently delete")} ${positionToDelete?.title}. ${tr("Are you sure you want to continue?")}`}
          confirmText="Delete"
          disableConfirmButton={isDeletingPosition}
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            setIsDeletingPosition(true)
            deletePositionRequest(positionToDelete!)
            .finally(() => setIsDeletingPosition(false))
            
            Analytics.sendPositionEvent('deleted', {
              position: positionToDelete as any,
              user: user,
              company: company
            })

            // On delete position
            setOriginalData(
              originalData.filter((p:any )=> p.id !== positionToDelete?.id)
            );
          }}
        />
        )}
      </PositionPageContext.Provider>
    </main>
  );
}

export default Positions;