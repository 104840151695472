import React, { useContext, useState } from 'react';
import { 
    CandidateNameAndRank, 
    CandidateProfile, 
    OurAdviceAndCandidateInsights, 
    ReportHeaderSection,
    CVHighlights,
    CommunicationStyleSection,
    ComprofileCompare,
    SynergyReccommendation,
    ReportLayout,
    CVAndQualifications,
    SynergyTable,
    ReccomendationCenter,
    VideoQuestion,
    QuestionsAnswersSection,
    RankOpenQuestions
} from './parts';

import ReactHtmlParser from 'react-html-parser';

import lightningIcon from './icons/navigation-icons/lightning.svg';
import wandIcon from './icons/navigation-icons/wand.svg';
import eyeIcon from './icons/navigation-icons/eye.svg';
import headsIcon from './icons/navigation-icons/heads.svg';
import heartIcon from './icons/navigation-icons/heart.svg';
import QnAIcon from './icons/navigation-icons/qna.svg';
import QuizIcon from './icons/navigation-icons/quiz.svg';
import reportLogoIcon from "./icons/report-logo.svg";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import { useHistory } from 'react-router';
import ShareReportModal from './parts/ShareReportModal/ShareReportModal';
import { InView } from 'react-intersection-observer';

import Modal from '../../../components/WizardComponents/Modal/Modal';
import { IReportData } from './hooks/useCandidateReport';

import ChatRooms from '@/pages/ChatRooms';

import './style.css';
import { ReportHeader } from './components';
import { navigateSmoothToDivById } from '@/helpers';
import { ChatWrapper, Modalbox } from '@/components';
import { StyledSelect } from '@/pages/Positions/components';
import { MenuItem } from '@mui/material';
import { proceedStatuses } from '@/pages/Talents/components';
import { ProceedStatus } from '@/pages/Talents/interfaces';
import { changeCandidateProceedStatus } from '@/pages/Talents/helpers';
import AppContext from '@/context/AppContext';


const candidateReport = {
    rank: 4,
    position: {
        candidates: 22,
    }
}

enum ReportSectionIds { 
    CandidateHighlights = "CandidateHighlights",
    CvAndQaulifications = "CvAndQaulifications",
    CandidateWorkStyle = "CandidateWorkStyle",
    CompetibillityForecast = "CompetibillityForecast",
    IfHired = "IfHired",
    Chat = "Chat",
    ProffessionalDeepDive = "ProffessionalDeepDive",
}


const CandidateReport: React.FunctionComponent<IReportData & { positionId: string }> = ({
    candidateData,
    cvData,
    positionData,
    comProfileForecast,
    videoData,
    reccomendations,
    loading,
    questionsAnswers,
    skippedQuestions,
    positionId,
    isUserRankAnswer,
    rankAnswer,
    setIsUserRankAnswer,
    getAndSetCandidateData,
    setCandidateStatus
}):JSX.Element => {
    
    const history = useHistory();
    
    const [showShareModal, setShowShareModal] = useState<boolean>(false);

    const [showVideoQuestionModal, setShowVideoQuestionModal] = useState<boolean>(false);
    
    const [showReccomendationModal, setShowReccomendationModal] = useState<boolean>(false);
    
    const [showRankOpenQuestionsModal, setShowRankOpenQuestionsModal] = useState<boolean>(false);
    const [questionIndex, setQuestionIndex] = useState<number>(0);

    const [activeSection, setActiveSection] = useState<string | undefined>();

    const [chatIframeProps, setChatIframeProps] = useState<{iframeId?: string, chatUrl?: string}>({});
    const [showChat, setShowChat] = useState<boolean>(false);

    const [status, setStatus] = useState<ProceedStatus | null>(null);
    const [isChangeCandidateStatus, setIsChangeCandidateStatus] = useState<boolean>(false);

    const { tr } = useContext(AppContext)

    const onInViewChanged = (inView: boolean, entery: IntersectionObserverEntry) => {
        if(inView) { 
            const sectionid = entery.target.id
            setActiveSection(sectionid)
        }
    }

    const onCloseRankQuestionModal = () => {
        setShowRankOpenQuestionsModal(false)
        if(isUserRankAnswer) {
            getAndSetCandidateData()
            .then(() => setIsUserRankAnswer(false))
        }
    }

    const onConfirmCandidateStatus = (status: ProceedStatus.go | ProceedStatus.notGo) => {
        setIsChangeCandidateStatus(true);
        changeCandidateProceedStatus({ candidateId: (candidateData?.id || ''), positionId: (candidateData?.V2PositionId || ''), status })
        .then(() => {
            setCandidateStatus(status)
        })
        .catch(console.error)
        .finally(() => {
            setStatus(null);
            setIsChangeCandidateStatus(false);
        });
        
    }

    const showIfHiredSection = (comProfileForecast?.avoidSurvivalModeBy && comProfileForecast.helpYourTalentThriveBy) ? true : false;
    const showCompetibillityForecastSection = (candidateData?.comProfile && positionData?.hrm?.comProfile && comProfileForecast?.summary) ? true : false;
    const showCVAndQualificationSection = (cvData && candidateData?.cv) ? true : false;
    const showCandidateWorkstyleSection = candidateData?.comProfileDetailed ? true : false;
    const showQuestionAnswerSection = videoData?.videoUrl || (questionsAnswers?.openQuestions && questionsAnswers.openQuestions.length > 0) || (questionsAnswers?.restOfQuestions && questionsAnswers.restOfQuestions.length > 0)
    const showChatSection = candidateData && chatIframeProps && chatIframeProps.iframeId && chatIframeProps.chatUrl ? true : false;

    const reportRoutes = [
        {
            label: "Candidate Highlights",
            iconSrc: lightningIcon,
            sectionid: ReportSectionIds.CandidateHighlights,
            printModeLabel: "Highlights",
            href: window.location.href
        },
        ...(showCVAndQualificationSection ? 
            [{
                label: "CV & Qualifications",
                iconSrc: wandIcon,
                sectionid: ReportSectionIds.CvAndQaulifications,
                printModeLabel: "CV",
                href: window.location.href
            }]: []),
        ...( showCandidateWorkstyleSection ? 
            [{
                label: "Candidate Workstyle",
                iconSrc: eyeIcon,
                sectionid: ReportSectionIds.CandidateWorkStyle,
                printModeLabel: "Workstyle",
                href: window.location.href
            }] : []),
        ...(showCompetibillityForecastSection ? 
            [{
                label: "Compatibility Forecast",
                iconSrc: headsIcon,
                sectionid: ReportSectionIds.CompetibillityForecast,
                printModeLabel: "Compatibility",
                href: window.location.href
            }] : []),
        ...(showQuestionAnswerSection ?
            [{
            label: "Review Answers",
            iconSrc: QnAIcon,
            sectionid: ReportSectionIds.ProffessionalDeepDive,
            printModeLabel: "Answers",
            href: window.location.href
            }] : []),
        ...(showIfHiredSection ? 
            [{
                label: "If Hired",
                iconSrc: heartIcon,
                sectionid: ReportSectionIds.IfHired,
                href: window.location.href
            }] : []),
        ...(showChatSection ? 
                [{
                    label: `Discuss ${candidateData?.firstName}'s candidacy`,
                    iconSrc: <MessageOutlinedIcon sx={{ color: "#FFF" }}/>,
                    sectionid: ReportSectionIds.Chat,
                    href: window.location.href
            }] : []),
        {
            label: "Hiring Quiz",
            iconSrc: QuizIcon,
            sectionid: "",
            href: window.location.href,
            onlyAts: true,
        },
    ]
    
    return (
        <>
        <div className='candidate-report-actions'>
            <span 
            onClick={() => history.push(`/positions/talents/${positionId}`)}
            className='back-to-all-candidates-report-btn no-print'
            >{`< All candidates`}</span>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                {candidateData && candidateData.id &&
                <ChatRooms
                onChatAvailable={(iframeId, chatUrl) => setChatIframeProps({ iframeId, chatUrl })}
                buttonProps={{
                    className: "custom-chat-btn-candidate",
                    onClick: () => {
                        setShowChat(true);
                        setTimeout(( )=> {
                            navigateSmoothToDivById(ReportSectionIds.Chat)
                            setActiveSection(ReportSectionIds.Chat)
                        }, 500)
                    }
                }}
                roomId={candidateData.id}>
                    <MessageOutlinedIcon sx={{ fontSize: "12px", color: "#B0B0B0", marginRight: "4px" }}/>
                    {`Discuss ${candidateData.firstName}'s candidacy`}
                </ChatRooms>}
                <StyledSelect
                sx={{ width: '130px' }}
                onChange={(e) => setStatus( e.target.value as ProceedStatus)}
                disabled={![undefined, null, ''].includes(candidateData?.status)}
                MenuProps={{ sx: { "& .MuiSelect-select": { fontSize: '14px' }, "& .MuiList-root": { padding: "0 !important"} } }}
                value={[undefined, null, ''].includes(candidateData?.status) ? ProceedStatus.pending : candidateData?.status}>
                    <MenuItem
                    disabled
                    sx={{ color: "#333" }}
                    value={ProceedStatus.pending}>{'Pending'}</MenuItem>
                    {Object.entries(proceedStatuses).map(([value, label]) => (
                        <MenuItem
                        value={value}
                        key={value}>{label}</MenuItem>
                    ))}
                </StyledSelect>
            </div>
        </div>
        <ReportLayout
        isLoading={loading}
        routes={reportRoutes}
        activeSection={activeSection}
        onLinkClicked={(sectionId) => setActiveSection(sectionId)}>
            <InView
            as={"div"}
            rootMargin="100px"
            threshold={1}
            onChange={onInViewChanged}
            id={ReportSectionIds.CandidateHighlights}>
                <ReportHeaderSection
                positionName={positionData?.title}
                reccommendation={candidateData?.refferalName}
                cvUrl={candidateData?.cv}
                submittedAt={Number(candidateData?.createdAt)}
                shareUrl={candidateData?.pdfUrl}
                onClickShareButton={() => setShowShareModal(true)}/>
                <div className='candiate-info-and-insights-section'>
                    <CandidateProfile
                    profileImage={candidateData?.imageUrl}
                    videoUrl={videoData?.videoUrl}
                    name={candidateData?.fullName}
                    email={candidateData?.email}
                    phone={candidateData?.phone}
                    linkedin={candidateData?.linkedinUrl}
                    onVideoPlayButtonClicked={() => setShowVideoQuestionModal(true)}/>
                    <div className='insights-section-wrapper'>
                        <CandidateNameAndRank
                        candidateName={candidateData?.fullName}
                        candidateRelevancy={candidateData?.relevancy}
                        candidateRank={candidateReport.rank}
                        positionCandidatesCount={candidateReport.position.candidates}/>
                        <OurAdviceAndCandidateInsights
                        className='our-advice-insights-section-wrapper'
                        onClickVerify={() => setShowRankOpenQuestionsModal(true)}
                        channels={candidateData?.relevancyPerChannel}
                        isSkillfullnessVerified={candidateData?.isSkillChannelVerified}
                        onAdviseClicked={() => setShowReccomendationModal(true)}
                        candidateRelevancy={candidateData?.relevancy}
                        candidateFirstName={candidateData?.firstName}/>
                    </div>
                </div>
                <div className='cv-highlights-wrapper'>
                    <CVHighlights
                    avgYearsPerEmployer={cvData?.avgYearsPerEmployer}
                    totalYearsExp={cvData?.totalYearsOfExperience}
                    totalYearsManagementExp={cvData?.totalYearsOfManagementExperience}
                    gaps={cvData?.gapsInEmpyment}
                    candidateSummary={cvData?.candidateSummary}
                    firstName={candidateData?.firstName}
                    skills={cvData?.skills}/>
                </div>
            </InView>
            {showCVAndQualificationSection &&
            <InView
            as={"div"}
            threshold={0.6}
            onChange={onInViewChanged}
            id={ReportSectionIds.CvAndQaulifications}>
                <CVAndQualifications
                onClickReadRecommendations={() => setShowReccomendationModal(true)}
                avgYearsPerEmployer={cvData?.avgYearsPerEmployer}
                totalYearsExp={cvData?.totalYearsOfExperience}
                totalYearsManagementExp={cvData?.totalYearsOfManagementExperience}
                gaps={cvData?.gapsInEmpyment}
                skills={cvData?.skills}
                fields={cvData?.fields}
                educations={cvData?.educations}
                cv={candidateData?.cv}
                />
            </InView>}
            {showCandidateWorkstyleSection &&
            <InView
            as={"div"}
            threshold={0.5}
            onChange={onInViewChanged}
            id={ReportSectionIds.CandidateWorkStyle}
            className='candidate-communication-style no-print'>
                {/* TODO - REFACTOR! */}
                <CommunicationStyleSection
                firstName={candidateData?.firstName}
                candidateComprofile={candidateData?.comProfileDetailed}/>
            </InView>}
            {showCompetibillityForecastSection &&
            <InView
            as={"div"}
            threshold={0.5}
            onChange={onInViewChanged}
            id={ReportSectionIds.CompetibillityForecast}
            className='hrm-candidate-communication-style no-print'>
                {/* TODO - REFACTOR! */}
                <ComprofileCompare
                candidateComProfile={candidateData?.comProfile}
                candidateFirstName={candidateData?.firstName}
                hrmComProfile={positionData?.hrm?.comProfile}/>
                {comProfileForecast && comProfileForecast?.summary &&
                <SynergyTable
                summary={comProfileForecast.summary}
                candidateComProfile={candidateData?.comProfile}
                hrmComProfile={positionData?.hrm?.comProfile}/>}
            </InView>}
            {showQuestionAnswerSection &&
            <InView
            as={"div"}
            threshold={0.05}
            onChange={onInViewChanged}
            className='no-print'
            id={ReportSectionIds.ProffessionalDeepDive}>
                <QuestionsAnswersSection
                videoQuestion={videoData?.videoQuestion?.question ? 
                    ReactHtmlParser(videoData.videoQuestion.question) : undefined
                }
                videoUrl={videoData?.videoUrl}
                openQuestions={questionsAnswers?.openQuestions ?? []} 
                restOfQuestions={questionsAnswers?.restOfQuestions ?? []}
                skipedQustions={skippedQuestions}
                candidateFirstName={candidateData?.firstName}
                onClickRankAnswer={(questionIndex => {
                    setQuestionIndex(questionIndex);
                    setShowRankOpenQuestionsModal(true);
                })}/>
            </InView>}
            {showIfHiredSection &&
            <InView
            as={"div"}
            threshold={0.5}
            onChange={onInViewChanged}
            className='if-hired-section no-print'
            id={ReportSectionIds.IfHired}>
                <SynergyReccommendation
                hiredSynergy={Object.values(comProfileForecast!.helpYourTalentThriveBy!).map(item => item)}
                avoidSynergy={Object.values(comProfileForecast!.avoidSurvivalModeBy!).map(item => item)}/>
            </InView>}
            
            {showChatSection &&
            <InView
            as={"div"}
            threshold={0.5}
            onChange={onInViewChanged}
            className='no-print'
            id={ReportSectionIds.Chat}>
                    <ChatWrapper 
                    showChat={showChat}
                    setShowChat={setShowChat}
                    chatIsAvailable={chatIframeProps && chatIframeProps.iframeId && chatIframeProps.chatUrl ? true : false}
                    chatContainerId={''} 
                    chatIframeProps={chatIframeProps as any}>
                        <ReportHeader>{`${tr("Discuss candidacy of")} ${candidateData?.firstName}`}</ReportHeader>
                    </ChatWrapper>
            </InView>}

            <img
            className='candidate-report-logo'
            src={reportLogoIcon}
            />

            </ReportLayout>

        {status &&
        <Modalbox
        buttonsWidth='140px'
        message={tr(
            status === ProceedStatus.go ? 
            'Are you sure you want to proceed with this candidate?' 
            : 'Are you sure you want to reject this candidate?')
        }
        description={tr(
            status === ProceedStatus.go ? 
            "This action will send a summarized version of this report to your ATS/inbox" : 'This action will send the candidate an immediate rejection email.'
        )}
        confirmText={tr("Yes")}
        disableConfirmButton={isChangeCandidateStatus}
        onCancel={() => setStatus(null)}
        onConfirm={() => onConfirmCandidateStatus(status as ProceedStatus.go | ProceedStatus.notGo)}
        />}

            {candidateData?.fullName && videoData?.videoUrl &&
            <Modal
            title={`${tr("Watch the video answer of")} ${candidateData?.firstName}`}
            openModal={showVideoQuestionModal}
            onClose={() => setShowVideoQuestionModal(false)}>
                <VideoQuestion
                videoUrl={videoData.videoUrl}
                videoQuestion={videoData.videoQuestion}
                />
            </Modal>}

            {questionsAnswers?.openQuestions && questionsAnswers?.openQuestions.length > 0 &&
            <Modal
            title={`${tr("Rank the answer of")} ${candidateData?.firstName ?? tr("the Talent")}`}
            openModal={showRankOpenQuestionsModal}
            onClose={() => onCloseRankQuestionModal()}>
                <RankOpenQuestions
                onDoneRankingAnswers={() => onCloseRankQuestionModal()}
                questionIndex={questionIndex}
                candidateFirstName={candidateData?.firstName}
                openQuestions={questionsAnswers.openQuestions}
                onSubmitQuestionRank={rankAnswer}/>
            </Modal>}

            <Modal
            openModal={showReccomendationModal}
            onClose={() => setShowReccomendationModal(false)}>
                <ReccomendationCenter
                    onClickAddRecommendationBtn={() => window.open("/hr-corner?openEditor=true", "_blank")}
                    firstName={candidateData?.firstName}
                    reccomendations={reccomendations} />
            </Modal>

            {showShareModal && candidateData?.pdfUrl && 
            candidateData?.email && positionData?.title &&
            <ShareReportModal 
            setShowShareModal={setShowShareModal} 
            pdfUrl={candidateData?.pdfUrl} 
            candidateEmail={candidateData?.email} 
            positionName={positionData?.title}/>}
        </>
    )
}

export default CandidateReport;