// React
import { useState, useEffect, useLayoutEffect, useContext, useCallback } from "react"

// Router
import { useHistory } from "react-router-dom"

// Auth hook
import { useAuth, IAuth } from "@auth"

// Context
import { WizardFormContext } from "@components/WizardForm/context"

// Components
import MainWizardContainer from "@components/WizardForm/containers"

// Helpers
import { validateQuestionCollection, validateQuestionList } from "@components/WizardForm/helpers"

// Interfaces
import { WizardCustomQuestionProps, IOnFinishParams } from "@components/WizardForm/interfaces"
import { IDisplayQuestion } from "@/models"
import { IFormPage, IFormPageCollection, QuestionCollection } from "@/models/formPage"
import { objectUtils } from "@/helpers"
import { MediaUploader } from "@/helpers/mediaUploader"
import AuthContext from "@/auth/context"
import useLocalToast from "../../hooks/useLocalizedToast";
import AppContext from "@/context/AppContext"

const WIZARD_TOAST_ID = "save-wizard"
interface IWizardPageChangeData {
	/**
	 * The page number
	 */
	readonly page: number
	/**
	 * The original model of this page
	 */
	readonly model: IFormPage
	/**
	 * The data collection of this page
	 */
	readonly data: IFormPage
}


/**
 * "none": don't show tab controls
 * "sequential" - show tab controls only the path up to the current tab
 * "all": show all the tab controls
 */
export type TabDisplayTypes = "none" | "sequential" | "all"

export type WizardDataUpdater = (dataCollection: IFormPage[]) => IFormPage[]

export interface IWizardPageCallbackParams {
	/**
	 * Information about the current page. Guaranteed not null, but the content may be null
	 */
	current: IWizardPageChangeData
	/**
	 * Information about the next page, if navigating to one. May be null and the content may be null
	 */
	next: IWizardPageChangeData | null,

	/**
	 * The current data collection, in case you need to peek into other pages
	 */
	dataCollection: IFormPage[],

	/**
	 * Authentication data, not available outside components
	 */
	auth: IAuth

	/**
	 * If false, no data has changed since the last save
	 */
	getDirty(): boolean

	/**
   * Has the data in the datacollection been changed since the last save?
   */
	setDirty: React.Dispatch<boolean>

}

export type WizardPageChangeStatus = "ok" | "saved" | "error"


interface IWizardFormProps {
	model: IFormPageCollection,
	className?: string
	onFinish?: (params: IOnFinishParams) => Promise<any>
	/**
	 * Return false to stop the page change
	 */
	onPageChange?: (params: IWizardPageCallbackParams) => Promise<WizardPageChangeStatus>
	/**
	 * If present, determines whether or not the finish button is shown
	 */
	canFinish?: (page: number, pageData: Partial<IFormPage>) => boolean

	showSave?: (page: number, pageData: Partial<IFormPage>) => boolean

	/**
	 * saved data, in a page collection format, that serves as the data collection if available
	 */
	defaultData?: IFormPage[]
	showTabs: TabDisplayTypes
	custom?: {
		[index: string]: React.FunctionComponent<WizardCustomQuestionProps>
	}
	/**
	 * An optional function to update the wizard's data collectino without recreating the entire tree
	 * It is monitored by a useEffect and runs only once per function instance
	 */
	dataUpdater?: WizardDataUpdater
}


/**
 * Move the datacollection custom questions into the questions array
 * @param pages
 * @returns
 */
const modelToDataCollection = (pages: IFormPage[]): IFormPage[] => {
	if (!pages || !pages.length) {
		console.warn("empty model")
		return []
	}
	const toDataRec = (sq: IDisplayQuestion, e: number) => (
		{
			key: e,
			type: sq.type,
			title: sq.title,
			required: sq.required,
			value: objectUtils.firstDefinedNotNull(sq.value, sq.defaultValue, "")
		})
	return pages.map((p: IFormPage, e: number) => ({
		key: e,
		hash: p.hash,
		name: p.name, // important so the collection can be manipulated as an array of IFormPage
		customQuestions: objectUtils.cloneObject(p.customQuestions || []),
		questions: (p.questions || [])
			.map(qlist => (
				qlist.map(toDataRec)
			))
	} as IFormPage))
}

/**
 *
 *  Wizard Form
 *  @description parent container for pages and questions, it manages global states
 *
 */
const WizardForm: React.FunctionComponent<IWizardFormProps> =
	({ model, onFinish, onPageChange, canFinish, showSave, dataUpdater, showTabs,
		custom, className, defaultData }): React.ReactElement => {

		const { settings } = useContext(AuthContext)
		const { locale, localizer } = useContext(AppContext);
		const { toast, update: updateToast } = useLocalToast();
		const alwaysSave = settings.getBooleanSetting("wizard.alwaysSave", false)
		// Auth
		const auth: IAuth = useAuth()

		// History
		const history = useHistory()

		// Options

		const [page, setPage] = useState<number>(-1)

		const [finishButtonText, setFinishButtonText] = useState<string>("");

		const [okButtonText, setOkButtonText] = useState<string>("");

		/**
		 * a global (per wizard instance) error that can be raised by any of the children
		 */
		const [error, setError] = useState<string | string[] | null>(null)

		const [validPage, setValidPage] = useState(true)

		const [dirty, setDirty] = alwaysSave ? [true, () => true] : useState(false)

		const [customQuestions, setCustomQuestions] = useState<any>(() => (
			model.pages.map((p: IFormPage) => p.customQuestions || (p.addFields ? [] : null))
		))

		/**
		 * A single image uploader serves all the children of this wizard instance
		 */
		const [imageUploader] = useState<MediaUploader>(new MediaUploader(settings))

		// Create a partial clone of the model's pages, which serves as the data context
		// for all the subcomponents
		const [dataCollection, setDataCollection] = useState<IFormPage[]>(() =>
			modelToDataCollection(defaultData || model?.pages)
		)

		const [localizedData, setLocalizedData] = useState<IFormPage[]>([]);

		const [localizedPages, setLocalizedPages] = useState<IFormPageCollection | null>(null)


		const [templateQuestions, setTemplateQuestions] = useState<QuestionCollection[]>(() => {
			return model?.pages?.map(page => page.questions)
		})

		const [finishLoading, setFinishLoading] = useState<boolean>(false)

		// Effects
		useLayoutEffect(() => {
			page >= 0 && history.push(`#${model.pages[page].hash || model.pages[page].key}`, history.location.state)
		}, [page])

		useEffect(() => {
			setTemplateQuestions(model?.pages?.map(page => page.questions) || null)
		}, [model])

		/**
		 * Display a toast alert if some child component has raised an error
		 */
		useEffect(() => {
			if (error) {
				toast(String(error), {
					type: "error",
					autoClose: 3000,
					toastId: WIZARD_TOAST_ID,
					containerId: "default"
				})
				setError(null)
			}
		}, [error])

		useEffect(() => {
			if (page >= 0) {
				setDirty(true)
			}
		}, [dataCollection])

		useEffect(() => {
			const hash = history.location.hash.replace("#", "")
			let pageIndex = hash ? model.pages.findIndex(
				p => (p.hash || p.key.toString()) === hash
			) : 0
			if (pageIndex < 0 && page < 0) {
				pageIndex = 0
			}

			if (pageIndex >= 0 && pageIndex !== page) {
				if (validatePage(page)) {
					switchPage(pageIndex)
				}
				else {
					const currentPage = dataCollection[page]
					const hash = currentPage?.hash || currentPage?.key
					if (hash !== undefined && hash !== null) {
						history.replace(`#${hash}`, history.location.state)
					}
				}
			}
		}, [history.location.hash])

		useEffect(() => {
			if (dataUpdater) {
				//console.log("data updater updated")
				setDataCollection(dataUpdater(dataCollection))
			}
		}, [dataUpdater])


		useEffect(() => {
			if (dataCollection) {
				const keys = ["questionsTitle", "noOptionsMessage", "questionsSubtitle", "name", "placeholder", "title", "subtitle", "valueType", "label", "defaultValue", "singularName"]
				setLocalizedData(localizer.localizeObject({
					data: dataCollection,
					locale,
					keys
				}))
				setLocalizedPages(localizer.localizeObject({ data: model, locale, keys }))

			}
		}, [dataCollection, locale, localizer])

		useEffect(() => {
			setFinishButtonText(localizer.localize({ key: model.options?.finishLabel || "Finish & Preview", locale }));
			setOkButtonText(localizer.localize({ key: model.options?.okLabel || "Next", locale }));
		}, [locale])

		/**
		 * Resolves to true if the page can be changed
		 * @param newPage 
		 * @returns 
		 */
		const testPageChange = useCallback(async (newPage: number): Promise<boolean> => {

			if (!ctx?.onPageChange) {
				return true
			}
			// If there are no pending transactions, this will return immediately
			const uploadResults = await imageUploader.done()
			if (uploadResults.hasErrors) {
				console.error("upload error", uploadResults.getAllErrors())
				return false
			}
			// Toast delay didn't work as expected - dismissing the toast before the delay was complete didn't prevent the toast
			// from appearing. Therefore, we set a timeout, knowing that if the page change callback does not return immediately, then
			// it must be doing some async stuff
			let toastId = ""
			let status = ""
			setTimeout(() => {
				if (status !== "ok") {
					toastId = WIZARD_TOAST_ID,
						toast("Saving...", {
							toastId,
							type: "info",
							autoClose: false,
							containerId: "default"
						})
				}
			}, 0)
			status = await ctx.onPageChange(createPageChangeData(page, newPage))
			if (status === "saved") {
				toastId && updateToast(toastId, {
					type: "success",
					autoClose: 1000,
					render: "Saved",
					containerId: "default"
				})
			}
			else if (status === "error") {
				toastId && updateToast(toastId, {
					type: "error",
					autoClose: 3000,
					render: "Error saving",
					containerId: "default"
				})
			}

			return status !== "error"
		}, [imageUploader])

		// Handlers

		const createPageChangeData = useCallback((currentPage: number, newPage?: number): IWizardPageCallbackParams => ({
			current: {
				page: currentPage,
				model: model.pages[currentPage],
				data: dataCollection[currentPage]
			},
			next: typeof newPage === "number" ? {
				page: newPage,
				model: model.pages[newPage],
				data: dataCollection[newPage]
			} : null,
			dataCollection,
			auth,
			getDirty: () => dirty,
			setDirty
		}), [])

		const switchPage = useCallback(async (newPage: number) => {
			if (newPage < 0 || newPage >= model.pages.length) {
				console.warn(`Wizard cannot navigate to non existent page ${newPage}`)
				return
			}
			// First time we've navigated to any page
			const go = await testPageChange(newPage);
			if (go !== false) { // owner needs to explicitly say NO
				setPage(newPage)
			}
		}, [])


		const validatePage = useCallback((page: number): boolean => {
			const formPage = dataCollection[page]
			// Defines if user can pass to next page
			const valid = validateQuestionCollection(formPage?.questions)
				&& validateQuestionCollection(formPage?.customQuestions)
			setValidPage(valid)
			if (!valid) {
				toast('Please fill in the required fields.', { containerId: "default" });
			}
			return valid
		}, [dataCollection])

		const handleFinish = useCallback(async () => {
			if (!validatePage(page)) {
				return
			}
			const go = await testPageChange(page);
			if (go) {
				setDirty(false)
				if (onFinish) {
					await imageUploader.done()
					onFinish({ dataCollection/*, history */, auth, setFinishLoading })
				}
			}
		}, [])

		// Global Handlers
		const handleNextPage = useCallback((pageIndex?: number): void => {
			if (pageIndex === undefined) {
				pageIndex = page + 1
			}
			if (pageIndex === page || pageIndex < 0 || pageIndex >= model.pages.length) {
				return
			}
			if (validatePage(page)) {
				switchPage(pageIndex)
			}
		}, [])

		const handlePrevPage = useCallback((): void => handleNextPage(page - 1), [])

		// Variables

		const progressBar = Boolean(model.options?.showProgress)
		const pressEnter = Boolean(model.options?.pressEnter)
		const showSaveButton = Boolean(showSave ? showSave(page, dataCollection[page]) :
			model.options?.showSave !== false)


		const autoUpload = Boolean(settings.hasSetting("autoupload"))

		// Context
		const ctx = {
			page,
			custom,
			onFinish,
			onPageChange,
			finishLoading,
			handleNextPage,
			handlePrevPage,
			handleFinish,
			canFinish,
			dataCollection: localizedData,
			customQuestions,
			templateQuestions,
			validateQuestion: validateQuestionList,
			setDataCollection,
			setCustomQuestions,
			pages: model.pages.length,
			uploader: imageUploader,
			autoUpload,
			setError
		}

		if (!localizedPages) {
			return <></>
		}

		return (
			<WizardFormContext.Provider value={ctx}>
				<MainWizardContainer
					model={localizedPages!}
					className={className + (validPage ? " wiz-valid-page" : " wiz-invalid-page")}
					progressBar={progressBar}
					showSave={showSaveButton}
					showTabs={showTabs}
					pressEnter={pressEnter}
					okButtonText={okButtonText}
					finishButtonText={finishButtonText}
				/>
			</WizardFormContext.Provider>
		)
	}

export default WizardForm
